<template>
  <div>
    <div class="login_register_grid">
      <section>
        <article class="w-100">
          <div class="row">
            <div class="col-md-12">
              <div style="display: flex; align-items: center; justify-content: center; " class="mb-4">
      

                    <img
                            :src="$store.state.logoImageUrl"
                            style="height: 7.8em"
                            alt=""
                            class="img-fluid "
                        />
              </div>
              
              <h3 class="mb-5 text-center">Ihamba Adventures Ltd Web Backoffice - Login</h3>
              <!-- <form> -->
                <div class="form-group">
                  <label for="username">Username</label>
                  <input
                    type="text"
                    v-model="username"
                    name="username"
                    
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    v-model="password"
                    name="password"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                    <button class="btn btn-primary btn-block" @click="login">
                        Login
                    </button>
                  <!-- <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> -->
                </div>
                <!-- <div>
                                    Do not have account?
                                    <router-link to="/register" class="btn btn-link">Create an account</router-link>
                                </div> -->
              <!-- </form> -->
            </div>
          </div>
          <div class="text-center mt-5">
               <small> &#169; copyright 2022 Ihamba Adventures</small>
          </div>
               
        </article>

      </section>
      <section>
        <article>
          <div>
            <img src="" class="w-100" />
          </div>
        </article>
      </section>
    </div>
  </div>
</template>

<script>
// import { mapState, mapActions } from "vuex";
import jwt_decode from "jwt-decode";

export default {
  data() {
    return {
        isBusy: false,
        username: "",
        password: "",
    };
  },
  methods: {
    async login() {
    //   if (this.$v.emailOrPhone.$invalid || this.$v.password.$invalid) return;
      try {
        this.isBusy = true;
        let loginData = {
          username: this.username,
          password: this.password,
        };
        let request = await this.$http.post("api/user/login", loginData );
        if (
          request.data.success && request.data.message == "LOGIN_SUCCESSFUL"
        ) {
            //   this.$store.commit("LOGIN_USER", request.data);
            if (request.data.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(request.data.token));
                 let decoded = jwt_decode(request.data.token);
                this.$store.commit('setCurrentUsername', decoded.data.username);
            }
          this.$router.replace({ path: "/" });
          this.$notify({
            title: "Login Successful",
            message: "You were successfully logged in.",
            type: 'success'
          });
        }else if (request.data.message == "INCORRECT_CREDENTIALS") {
          this.$notify({
            title: "Incorrect Credentials",
            message: "The Credentials you supplied were invalid. Please review them and try again.",
            type: 'warning'
          });
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        if (error.message === "Network Error") {
          this.$notify({
            title: "Connection Failed",
            message: "Unable to Connect. Please check your Internet Connection and try again.",
            type: 'error'
          });
        }

        this.$notify({
            title: "Login Failed",
            message: "Unable to Complete login now. Please try again.",
            type: 'error'
        });
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style scoped>
  .login_register_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .login_register_grid > section {
    height: 100vh;
  }

  .login_register_grid > section:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 100px;
  }

  .login_register_grid > section:nth-child(2) {
    background-image: url(https://development.locushoteluganda.com/img/dine2.193ff84e.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
    .login_register_grid {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .login_register_grid {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) {
    .login_register_grid {
      display: grid;
      grid-template-columns: 1fr;
    }
    .login_register_grid > section:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 20px;
    }
    .login_register_grid > section:nth-child(2) {
      display: none;
    }
  }

  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) {
    .login_register_grid {
      display: grid;
      grid-template-columns: 1fr;
    }
    .login_register_grid > section:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 20px;
    }
    .login_register_grid > section:nth-child(2) {
      display: none;
    }
  }
</style>